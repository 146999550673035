.not-found{
    width: 100%;
    overflow: hidden;
    position: relative;
}

.sec-404{
    height: 100vh;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #657786;
}

.sec-404 h2{
    font-size: 2rem;
    font-weight: 500;
    line-height: 140%;
    margin-top: 20px;
    color: #000;
}

.sec-404 p{
    width: 30%;
    margin: 5px 0 0 0;
    text-align: center;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.7);
}

.sec-404 a{
    transition: all .15s ease-in-out;
    text-decoration: none;
    color: #2993ec;
}

.not-found .div-logo{
    position: fixed;
    padding: 20px;
    z-index: 10;
}

.not-found .div-logo img{
    height: 44px;
    width: auto;
}

.not-found .bg-square{
    position: absolute;
    top: -22vw;
    right: -75vw;
    width: 100vw;
    padding-top: 100%;
    border-radius: 5%;
    background-color: rgba(41, 147, 236, 0.1);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(35deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    z-index: 0;
}

@media only screen and (max-device-width: 600px) {
    .sec-404 h2{
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .sec-404 p{
        width: 80%;
        line-height: 1.4em;
        font-size: 19px;
    }
}