@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html::-webkit-scrollbar{
  display: none;
}

body {
  margin: 0;
  background-color: #fbfbfb;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.none{
  display: none;
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.flex-center{
  display: flex;
  justify-items: center;
  align-items: center;
}

.container-box{
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(145, 158, 171, 0.24);
}

.profile-container,
.analytics-container,
.add-place-container,
.finance-container,
.places-container{
  width: 100%;
  padding: 40px 60px;
}

.itineraries-container{
  width: auto;
  padding: 40px 60px;
}

.edit-button,
.save-button,
.cancel-button{
  font-size: 14px;
  line-height: 20px;
  min-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: none;
}

.save-button{
  width: 44px;
}

.save-button:disabled:hover,
.save-button svg:hover{
  cursor: not-allowed;
}

.edit-button,
.cancel-button{
  padding: 0px 8px;
  color: rgb(64, 68, 82);
  background-color: #fff;
}

.edit-button,
.cancel-button{
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
}

.edit-button svg{
  margin-left: 5px;
  margin-bottom: 2px;
}

.edit-button:hover,
.cancel-button:hover,
.save-button:hover{
  cursor: pointer;
}

.cancel-save-btns-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.save-button{
  background-color: rgb(63, 163, 240);
  color: #fff;
}

.user-input label,
.pc-info-mc-div label,
.npc-place-name-div label,
.npc-place-container-label{
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.015em;
  display: block;
  color: #292d34;
  padding: 0 0 8px 2px;
}

.user-input{
  position: relative;
  margin-bottom: 25px;
}

.user-input input,
.user-input textarea,
.npc-place-name-div input{
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  border: 1px solid #e9ebf0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 20px 8px 40px;
  color: #292d34;
  transition: all ease-in-out 0.15s;
}

.user-input.password input{
  padding: 8px 130px 8px 40px;
}

.user-input input:focus,
.user-input textarea:focus,
.sie-input-div input:focus,
.npc-place-name-div input:focus{
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.user-input input::placeholder,
.user-input textarea::placeholder,
.npc-place-name-div input::placeholder{
  color: #b9bec7;
  font-size: 16px;
  letter-spacing: 0.025em;
}

.background-image{
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  background-color: rgb(245, 245, 245);
}

.loading-div{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.3);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.user-input.error input,
.npc-place-name-div.error input,
.npc-place-summary-input.error textarea{
  border-color: #e70000;
}

#draggable{
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
}

.secton-hdr-info-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.secton-hdr-info-container svg{
  margin-left: 3px;
}

.secton-hdr-info-container:hover{
  cursor: pointer;
}

.small-screen-cover-container{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  z-index: 1500;

  & .div-logo{
      left: 0;
      padding: 20px;
      position: fixed;
      top: 0;
      z-index: 10;
  }

  & .div-logo img{
      height: 44px;
      width: auto;
  }

  & .ssc-container{
      position: relative;
      display: flex;
      flex-direction: column;
      width: 480px;
      height: auto;
      box-sizing: border-box;
      background: #fff;
      box-shadow: 0 25px 75px rgba(16,30,54,.25);
      border-radius: 6px;
      padding: 30px 60px 26px;
      transition: all ease-in-out 0.15s;

      & .ssc-body{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          & p{
              margin-top: 20px;
              line-height: 27px;
              text-align: center;
              color: rgba(0,0,0,.7);
          }
      }
  }
}

.sb-web{
  display: none;
}

@media (max-width: 1349px){
  .profile-container, 
  .analytics-container, 
  .add-place-container, 
  .finance-container, 
  .places-container,
  .itineraries-container{
    padding: 30px;
    box-sizing: content-box;
  }
}


@media (max-width: 1249px){
  .profile-container, 
  .analytics-container, 
  .add-place-container, 
  .finance-container, 
  .places-container{
    width: calc(100vw - 280px - 60px);
  }
}

@media (max-width: 1099px){
  .profile-container, 
  .analytics-container, 
  .add-place-container, 
  .finance-container, 
  .places-container{
    width: 90%;
  }
}

@media only screen and (max-device-width: 600px){
  .user-input input, 
  .user-input textarea, 
  .npc-place-name-div input{
    font-size: 16px;
  }

  .profile-container, 
  .analytics-container, 
  .add-place-container, 
  .finance-container, 
  .places-container, 
  .itineraries-container{
    width: calc(100% - 30px);
    padding: 15px;
  }
}