.profile-img{
    position: relative;
    height: 200px;
    width: 200px;
    margin: 0 30px;
    border-radius: 200px;
    border: 2px solid rgb(235, 238, 240);
    box-shadow: 0 8px 16px 0 rgba(145, 158, 171, 0.24);
    border: 2px solid #fff;
    box-sizing: content-box;
}

.pc-info-header,
.pc-pricing-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.pc-info-header h3,
.pc-pricing-header h3{
    margin: 0;
    font-size: 22px;
    letter-spacing: 0.025em;
}

.pc-info-div{
    display: flex;
    flex-direction: row;
}

.pc-info-paper{
    width: 100%;
    margin-left: 30px;
}

.pc-pricing-paper{
    margin-top: 30px;
}

.profile-container .user-input input{
    width: 300px;
    padding: 8px 20px;
}

.profile-container .last-name,
.profile-container .country,
.pc-info-mc-div{
    margin-left: 20px;
}

.pc-pricing-main .user-input input{
    width: 150px;
    padding-left: 40px;
}

.profile-container .user-input textarea{
    width: 100%;
    height: 100px;
    resize: none;
    padding: 8px 20px;
    overflow: scroll;
}

.pc-info-line-1,
.pc-info-line-2{
    display: flex;
    flex-direction: row;
}

.pc-info-line-2{
    margin-top: 10px;
}

.pc-info-mc-div{
    display: flex;
    flex-direction: column;
}

.pc-info-mc-div button{
    height: 40px;
    border: 1px solid #e9ebf0;
    border-radius: 4px;
    color: #292d34;
}

.pc-info-mc-div button:hover{
    cursor: pointer;
}

.pc-pricing-main{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.pc-pricing-main .dollar-icon{
    position: absolute;
    left: 8px;
    bottom: 8px;
}

.pc-image-plan-trip-switch .MuiSwitch-colorSecondary{
    color: rgb(255, 90, 95);
}

.pc-image-plan-trip-switch .MuiSwitch-colorSecondary.Mui-checked{
    color: rgb(0, 166, 153);
}

.pc-image-plan-trip-switch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
    background-color: #a2b7c7;
}

.pc-image-plan-trip-switch-paper{
    position: relative;
    margin-top: 30px;
    width: 215px;
    transition: all 0.25s ease-in-out;
}

.pc-imps-cover{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-in-out;
    border-radius: 8px;
    z-index: 1000;
}

.pc-image-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.pcis-info-container{
    position: absolute;
    top: 5px;
    right: 5px;
}

.pcis-info-container:hover{
    cursor: pointer;
}

.itinerary-price-msg{
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 0;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.025em;
    opacity: 0;
    user-select: none;
}

.itinerary-price-msg span{
    color: #f59e0c;
}

.itinerary-price-msg svg{
    margin-right: 4px;
    color: #f59e0c;
}

.itinerary-price-msg-show{
    animation: itinerary-price-msg-show 0.6s ease-in-out forwards;
}

.pc-img-action-btn{
    position: absolute;
    height: 33px;
    width: 33px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: rgb(63, 163, 240);
    border: 3px solid #fff;
    box-sizing: content-box;
    z-index: 101;
}

.pc-iab-link{
    bottom: 5px;
}

.pc-iab-edit{
    top: 5px;
}

.pc-iab-edit:hover{
    cursor: pointer;
}

.pc-image-cover{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: rgba(0,0,0, 0.3);
    z-index: 100;
}

.pc-survey-link-copy-btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 3px;
    background-color: rgb(63, 163, 240);
    color: #fff;
    padding: 5px;
    transition: all 0.25s ease-in-out;

    & svg{
        margin-right: 10px;
    }
}

@media (max-width: 1399px){
    .profile-container img{
        width: 175px;
        height: 175px;
    }

    .profile-container .user-input input{
        width: 200px;
    }

    .pc-pricing-main .user-input input{
        width: 130px;
    }
}

@media (max-width: 1249px){
    .pc-info-div{
        flex-direction: column;
        gap: 30px;
    }

    .pc-image-column{
        flex-direction: row;
        gap: 20px;

        padding-top: 0;
        justify-content: center;
    }

    .pc-image-plan-trip-switch-paper{
        margin-top: 0;
    }

    .pc-info-paper{
        margin-left: 0px;
    }
}

@media only screen and (max-device-width: 600px){
    .pc-image-column{
        flex-direction: column;

        & .pc-image-plan-trip-switch-paper{
            margin-top: 5px;
            width: 220px;
        }
    }

    .pc-info-paper{
        & .pc-info-mc-div{
            display: none;
        }

        & .pc-info-line-1, 
        & .pc-info-line-2{
            flex-direction: column;
        }

        & .last-name,
        & .country,
        & .pc-info-mc-div{
            margin-left: 0px;
        }

        & .user-input input{
            width: 100%;
        }
    }

    .pc-pricing-paper{
        & .pc-pricing-main .user-input input{
            width: 35vw;
        }

        & .itinerary-price-msg{
            bottom: -22px;
            background-color: #fff;
            padding: 6px;
            border-radius: 0.5rem;
            box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
        }
    }
}

@keyframes itinerary-price-msg-show{
    0% {
      bottom: -40px;
      opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
      bottom: -15px;
      opacity: 1;
    }
}