.places-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.pc-place-div{
    width: 330px;
    border: 1px solid rgb(235, 238, 240);
    border-radius: 4px;
    background-color: #fff;
}

.pcp-location-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 3%;
}

.pcp-location-div p{
    margin: 0 0 0 2px;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    width: calc(100% - 50px);
}

.pcp-images-div-main{
    position: relative;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    grid-column-gap: 0;
    grid-row-gap: 3px;
    width: 100%;
    background-color: #fff;
}

.pcp-image-1{
    height: 165px;
    grid-area: 1/1/auto/auto;
}

.pcp-images-div-secondary{
    height: 165px;
    display: grid;
    grid-area: 2/1/auto/auto;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-column-gap: 3px;
}

.pcp-image-2{
    height: 100%;
    grid-area: 1/1/auto/auto;
}

.pcp-image-3{
    height: 100%;
    grid-area: 1/2/auto/auto;
}

.pc-place-div{
    margin: 0 15px 40px 15px;
}

.pcp-images-div-main .edit-button{
    position: absolute;
    right: 5px;
    top: 5px;
    transition: all 0.15s ease-in-out;
}

.pcp-images-div-main .edit-button:hover{
    opacity: 0.9;
}

.pcp-summary{
    margin: 10px 3% 0 3%;
    font-size: 14px;
    padding: 0 3px;
    background-color: transparent;
    line-height: 140%;
    color: rgba(0,0,0,.7);
}

.pcp-summary:hover{
    cursor: pointer;
}

.pcp-summary span{
    color: #737373;
}

.pcp-stats-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    margin-top: 10px;
    padding: 0 3%;
}

.pcp-views,
.pcp-shares{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pcp-views{
    margin-right: 12px;
}

.pcp-views svg,
.pcp-shares svg{
    margin-right: 3px;
}

.pcp-date-div{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 3%;
    font-size: 14px;
    color: rgba(0,0,0,.7);
}

.pd-modal-container{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: auto;
    transition: all 6.25s ease-in-out;
    width: 750px;
    height: 100vh;
}

.place-details-modal .mantine-kea9ny{
    z-index: 2000;
}

.place-details-modal .mantine-xiucpp{
    z-index: 2001;
}

.place-details-modal .mantine-1q36a81{
    padding: 0;
}

.pd-images-container{
    position: relative;
    height: 600px;
    width: 732px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    transition: all 0.25s ease-in-out;
}

.pd-modal-img-square{
    height: 500px;
    width: 500px;
}

.pd-modal-img-landscape{
    height: 500px;
    width: calc(500px * 16 / 9);
}

.pd-modal-img-portrait{
    height: 500px;
    width: calc(500px * 4 / 5);
}

.pd-images-container .image-indicators-div{
    bottom: 20px;
}

.pd-location-div{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pd-images-container .dad-img-remove-btn{
    display: none;
}

.place-details-modal .image-slide-reorder-btn{
    bottom: 10px;
}

.pd-modal-loading-cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.pd-modal-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
    margin-bottom: 20px;
    margin-top: 20px;
}

.pd-modal-summary-div{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 25px auto 0 auto;
}

.pd-modal-summary-div textarea{
    padding: 8px 20px;
    height: calc(100vh - 600px - 78px - 25px - 38px - 50px);
}

.pd-modal-summary-div .user-input{
    margin-bottom: 10px;
}

.place-details-modal .mantine-1871i9p{
    z-index: 2001;
}

.infinite-scroll-places-div{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.infinite-scroll-places-div::-webkit-scrollbar{
    display: none;
}

.pd-modal-summary-div .user-input.error .helper-txt{
    bottom: -12px;
    top: inherit;
}

.pd-modal-summary-div .user-input.error textarea{
    border-color: #e70000;
}

.pc-empty-section{
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pc-empty-section p{
    width: 30%;
    margin-top: 10px;
    line-height: 27px;
    text-align: center;
    color: rgba(0,0,0,.7);
}

.pc-is-loading-section{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1099px){
    .places-div{
      justify-content: center;
    }
}

@media only screen and (max-device-width: 600px){
    .pd-modal-header{
        margin-bottom: 15px;
        margin-top: 15px;
        padding: 5px 2%;
    }

    .pc-place-div{
        width: 100%;
        margin: 0 0px 40px 0px;
    }

    .pd-modal-container{
        width: 100%;
        min-height: 100vh;
        height: auto;
        
        .pd-images-container{
            width: 100vw;
            height: auto;
            min-height: calc(96vw + 50px);
            margin-top: 60px;
            margin-bottom: 20px;

            & .image-slide-right-btn{
                right: 10px;
            }

            & .image-slide-left-btn{
                left: 10px;
            }

            & .pd-modal-img-square{
                height: 96vw;
                width: 96vw;
            }
            
            & .pd-modal-img-landscape{
                height: calc(96vw * 9 / 16);
                width: 96vw;
            }
            
            & .pd-modal-img-portrait{
                height: 96vw;
                width: calc(96vw * 4 / 5);
            }
        }  
    }

    .pc-empty-section p{
        width: 60%;
    }

    .pd-modal-summary-div textarea{
        height: 200%;
    }

    .place-details-modal .mantine-xiucpp{
        width: 100vw;
        padding: 0;
    }
}