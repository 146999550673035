.fc-container-line-1{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.fc-container-line-1-clm-1{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.fc-container-line-1-clm-1 .mx-auto{
    width: 210px;
}

.fc-container-line-2{
    margin-top: 30px;
}

.fc-container-line-2 .w-full{
    width: 960px;
}

.fc-container-line-1-clm-2 .mx-auto{
    width: 100%;
}

.fc-itinerary-table-header-div{
    margin-top: 20px;
    margin-bottom: 5px;
}

.fc-itinerary-div{
    border: 1px solid #e9ebf0;
    border-radius: 10px;
    margin-bottom: 15px;
}

.fc-itinerary-div,
.fc-itinerary-table-header-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
}

.fc-itinerary-table-header-div .fci-cell{
    font-weight: 600;
}

.fci-cell-breakline{
    height: 25px;
    margin: 0 15px;
    background-color: #e9ebf0;
    width: 2px;
}

.fci-cell{
    width: 100px;
    text-align: center;
}

.fc-ibh-container{
    width: 190px;
}

.fc-empty-section{
    height: 30vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fc-empty-section p{
    width: 30%;
    margin-top: 10px;
    line-height: 27px;
    text-align: center;
    color: rgba(0,0,0,.7);
}

@media (max-width: 1299px){
    .fci-cell-status,
    .fci-cell-breakline-status{
        display: none;
    }

    .fc-container-line-2 .w-full{
        width: 860px;
    }
}

@media (max-width: 1199px){
    .fci-cell-paid,
    .fci-cell-breakline-paid{
        display: none;
    }

    .fc-container-line-2 .w-full{
        width: 720px;
    }
}

@media only screen and (min-device-width: 601px){
    .finance-container{
        & .ic-cell-see-more{
            display: none;
        }
    }
}

@media only screen and (max-device-width: 600px){
    .fc-container-line-1{
        flex-direction: column;
        justify-content: space-between;

        & .fc-container-line-1-clm-1{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    .fc-container-line-2{
        & .w-full{
            width: 100%;
        }

        & .fci-cell-breakline{
            margin: 0 5px;
        }

        & .fci-cell-trip-type-breakline,
        & .fci-cell-trip-price,
        & .fci-cell-trip-price-breakline,
        & .fci-cell-arvel-fee,
        & .fci-cell-arvel-fee-breakline,
        & .fci-cell-total,
        & .fci-cell-breakline-status,
        & .fci-cell-status{
            display: none;
        }
    }

    .fc-itinerary-div, 
    .fc-itinerary-table-header-div{
        padding: 10px;
    }

    .id-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px 0;

        & .row{
            display: flex;
            flex-direction: row;

            & p{
               width: 100px;
            }

            & .title{
                font-weight: 600;
            }
        }

        .breakline{
            height: 2px;
            width: 200px;
            margin: 10px 0px;
            background-color: #e9ebf0;
        }
    }

    .finance-container{
        & .id-body{
            & .row{
                display: flex;
                flex-direction: row;
            }
        }
    }

    .fc-empty-section{
        & svg{
            width: 60px;
            height: 60px;
        }

        & p{
            width: 90%;
        }
    }
}