.add-place-container{
    position: relative;
    min-height: 80vh;
}

.places-container{
  width: 100%;
}

.npc-select-files-card,
.npc-review-files-card,
.npc-add-info-card,
.npc-place-loading{
    position: relative;
    height: calc(100vh - 180px);
    padding: 0;
    display: flex;
    flex-direction: column;
}

.npc-select-files-header,
.npc-review-files-header,
.npc-add-info-header,
.npc-place-loading-header{
    text-align: center;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
}

.ane-close-btn{
    position: absolute;
    width: 70px;
    right: 20px;
    top: 20px;
}

.npc-select-files-header{
    display: flex;
    justify-content: center;
    align-items: center;
}

.npc-place-loading-header{
    position: relative;
    justify-content: center;
}

.npc-place-loading-header .cancel-button{
    position: absolute;
    right: 10px;
    top: 8px;
}

.npc-review-files-header,
.npc-add-info-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 15px;
}

.npc-select-files-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
}

.npc-select-files-body h3{
    display: flex;
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
}

.npc-select-files-body p{
    margin-left: 5px;
    font-weight: 500;
    letter-spacing: 0.025em;
}

.apc-select-files{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    background-color: rgb(63, 163, 240);
    margin-top: 20px;
    padding: 7px 0;
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
    transition: all 0.25s ease-in-out;
}

.apc-select-files:hover{
    cursor: pointer;
    opacity: 0.8;
}

.apc-select-files input[type="file"] {
    display: none;
}

.npc-review-next-btn,
.npc-add-info-next-btn{
    color: rgb(63, 163, 240);
    font-size: 15px;
    font-weight: 600;
}

.npm-review-files-body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.npm-review-img-div{
    position: relative;
    height: 100%;
    width: calc(50vh * 16 / 9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.npm-review-img-square{
    height: 50vh;
    width: 50vh;
}

.npm-review-img-landscape{
    height: 50vh;
    width: calc(50vh * 16 / 9);
}

.npm-review-img-portrait{
    height: 50vh;
    width: calc(50vh * 4 / 5);
}

.npm-review-img-div .img-indicator,
.pd-images-container .img-indicator{
    width: 6px;
    height: 6px;
    border-radius: 6px;
}

.image-indicators-div{
    position: absolute;
    bottom: 25px;
    display: flex;
    flex-direction: row;
}

.image-slide-left-btn,
.image-slide-right-btn,
.image-slide-reorder-btn{
    position: absolute;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: rgba(26, 26, 26, .8);
    box-sizing: 0 4px 12px rgba(0, 0, 0, .15);
    transition: all 0.25s ease-in-out;
}

.image-slide-left-btn{
    left: 3vh;
}

.image-slide-right-btn{
    right: 3vh;
}

.image-slide-reorder-btn{
    bottom: 25px;
    right: 3vh;
}

.image-slide-left-btn:hover,
.image-slide-right-btn:hover{
    background-color: rgba(26, 26, 26, 1);
}

.image-reorder-container{
    position: absolute;
    min-height: 118px;
    max-width: 652px;
    height: auto;
    padding: 8px;
    border-radius: 3px;
    background-color: rgba(26, 26, 26, .8);
    display: none;
}

.irc-show{
    right: 3vh;
    display: inherit;
    animation: image-reorder-container-show 0.6s ease-in-out forwards;
}

.apc-image-reorder-img{
    position: relative;
    width: 94px;
    height: 94px;
    margin: 4px 6px;
    border-radius: 2px;
    transition: all 0.25s ease-in-out;
}

.dad-img-remove-btn{
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(26, 26, 26, .8);
    border-radius: 20px;
}

.click-and-drag-info-popup{
    position: absolute;
    top: -45px;
    height: 38px;
    left: calc((100% - 200px)/2);
    display: none;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #000;
    border-radius: 8px;
    padding: 4px 15px;
    letter-spacing: 0.015em;
    transition: all 0.25s ease-in-out;   
}

.cad-ip-show-show{
    display: inherit;
    animation: image-reorder-container-show 0.6s ease-in-out forwards;
}

.npc-error-msg{
    color: #e70000;
}

.accorion-info-add-container{
    width: 50%;
    margin: auto;
    padding-top: 2%;
    padding-bottom: 6%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.npc-place-name-div input,
.npc-place-location-div input{
    height: 40px;
    padding: 10px 20px;
}

.npc-place-name-div{
    position: relative;
    padding: 15px 0;
}

.npc-location-coords-div,
.npc-location-info-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.npc-location-coords-div{
    margin-top: 20px;
    & .user-input{
        width: 47%;
    }
}

.npc-location-info-div{
    margin-top: 10px;
    & .user-input{
        width: 30%;
    }
}

.npc-location-coords-div input{
    width: 100%;
}

.npc-add-info-card .user-input textarea{
    padding: 8px 20px;
    min-height: 60px;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    line-height: 21px;
}

.npc-caption-character-cnt{
    position: absolute;
    right: 5px;
    color: rgb(199, 199, 199);
    font-size: 12px;
}

.npc-categories-div{
    margin: 15px 0;
}

.npc-place-types-container{
    margin: 20px 0 15px 0;
}

.npc-place-types-div{
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 10px;
}

.npm-place-type-chip img{
    width: 18px;
    height: 18px;
    opacity: 0.5;
    margin-right: 8px;
}

.npc-place-name-error,
.npc-place-types-error,
.npc-place-summary-error{
    position: absolute;
    color: #e70000;
    float: left;
    font-size: 12px;
    letter-spacing: 1px;
}

.npc-place-name-error{
    left: 3px;
}

.npc-place-types-error{
    bottom: -25px;
}

.npc-place-summary-error{
    bottom: 8px;
}

.npc-summary.mantine-1h0npq1{
    border-bottom: none;
}

.npm-place-type-chip .mantine-xmcz5n{
    color: rgb(63, 163, 240) !important;
}

.npm-place-type-chip .mantine-1iyhplg[data-disabled]{
    color: inherit !important;
}

.mantine-1iyhplg[data-checked] {
    border: 0.0625rem solid rgb(63, 163, 240) !important;
}

.dad-image-selected-frame{
    position: absolute;
    top: -2px;
    left: -2px;
    width: 98px;
    height: 98px;
    border-radius: 2px;
    border: 2px solid rgb(235, 235, 235);
    background-color: transparent;
    transition: all 0.25s ease-in-out;
    z-index: -1;
}

.npc-place-container-label{
    padding-bottom: 15px;
}

.npc-pl-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 6%;
    height: 100%;
    width: 100%;
}

.npc-pl-container h3{
    margin-top: 45px;
    font-size: 20px;
    text-align: center;
    color: #000;
    font-weight: 600;
}

.npc-pl-container p{
    margin-top: 10px;
    line-height: 27px;
    text-align: center;
    color: rgba(0,0,0,.7);
}

@media (max-width: 1350px){
    .npm-review-img-landscape{
        height: 45vh;
        width: calc(45vh * 16 / 9);
    }

    .accorion-info-add-container{
        width: 60%;
    }
}


@media (max-width: 1199px){
    .npm-review-img-landscape{
        height: 40vh;
        width: calc(40vh * 16 / 9);
    }

    .accorion-info-add-container{
        width: 70%;
    }
}

@media only screen and (max-device-width: 600px){
    .accorion-info-add-container {
        width: 94%;

        & .npc-select-files-card, 
        & .npc-review-files-card, 
        & .npc-add-info-card, 
        & .npc-place-loading{
            min-height: calc(100vh - 180px);
        }
    }

    .npc-pl-container p{
        width: 80%;
    }

    .npm-review-files-body{
        height: 100%;
        min-height: 65vh;

        & .npm-review-img-div{
            height: 65vh;
            width: 100%;
        }

        & .npm-review-img-square{
            height: 96vw;
            width: 96vw;
        }
        
        & .npm-review-img-landscape{
            height: calc(90vw * 9 / 16);
            width: 90vw;
        }
        
        & .npm-review-img-portrait{
            height: 96vw;
            width: calc(96vw * 4 / 5);
        }
    }

    .npc-select-files-card, 
    .npc-review-files-card, 
    .npc-add-info-card, 
    .npc-place-loading{
        min-height: calc(100vh - 180px);
        height: auto;
    }

    .npc-select-files-body{
        margin-top: 40%;
    }
}

@keyframes image-reorder-container-show{
    0% {
      bottom: 20px;
      opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
      bottom: 70px;
      opacity: 1;
    }
}