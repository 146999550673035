.ic-itinerary-table-header-div,
.ic-itinerary-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
}

.ic-itinerary-table-header-div{
    margin-top: 20px;
    margin-bottom: 10px;
}

.ic-itinerary-div{
    margin-bottom: 10px;
}

.ic-itinerary-table-header-div .ici-cell{
    width: 120px;
    text-align: center;
    font-weight: 600;
}

.ic-itinerary-div .ici-cell{
    width: 120px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ici-cell-breakline{
    height: 25px;
    margin: 0 15px;
    background-color: #e9ebf0;
    width: 2px;
}

.ic-itinerary-div{
    border: 1px solid #e9ebf0;
    border-radius: 10px;
    margin-bottom: 15px;
}

.ic-cell-see-more{
    width: 120px;
    color: rgb(63, 163, 240);
}

.ic-close-btn{
    position: absolute;
    border: none;
    background-color: transparent;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ic-close-btn:focus{
    outline: 0;
}

.itinerary-details-modal .mantine-kea9ny{
    z-index: 2000;
}

.itinerary-details-modal .mantine-xiucpp{
    z-index: 2001;
}

.ic-id-question-div{
    margin-bottom: 20px;
}

.ic-id-question-response{
    font-size: 16px;
    margin-top: 3px;
    margin-left: 20px;
    line-height: 140%;
    color: rgba(0,0,0,0.8);
}

.itinerary-details-modal .mantine-1q36a81{
    padding: 24px;
}

.ic-completed-card-title,
.ic-pending-card-title{
    margin-bottom: 10px;
    padding: 8px 20px;
    box-sizing: content-box;
    border-radius: 10px;
    color: #000;
}

.ic-completed-card-title{
    background-color: rgba(0, 166, 153, 0.1);
    width: 195px;
}

.ic-pending-card-title{
    background-color: rgba(252, 100, 45, 0.1);
    width: 170px;
}

.ic-empty-section{
    height: 20vh;
    padding-right: 120px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ic-empty-section p{
    width: 30%;
    margin-top: 10px;
    line-height: 27px;
    text-align: center;
    color: rgba(0,0,0,.7);
}

.idm-sq-section-header-container{
    margin-top: 10px;
    margin-bottom: 20px;
    width: 230px;
}

@media (max-width: 1299px){
    .ici-cell-destination,
    .ici-cell-breakline-destination,
    .ici-cell-destination-breakline{
        display: none;
    }

    .ic-cell-see-more,
    .ic-itinerary-table-header-div .ici-cell-btn{
        width: 90px;
    }

    .ic-empty-section p{
        width: 50%;
    }
}

@media only screen and (max-device-width: 600px){
    .ic-itinerary-table-header-div{
        padding: 10px;

        & .ici-cell-breakline{
            margin: 0 5px;
        }

        & .ici-cell{
            width: 105px;
        }

        & .ici-cell-received,
        & .ici-cell-received-breakline,
        & .ici-cell-price,
        & .ici-cell-price-breakline,
        & .ici-cell-destination-breakline,
        & .ici-cell-trip-type-breakline{
            display: none;
        }

        & .ici-cell-btn{
            width: 50px;
        }
    }

    .ic-itinerary-div{
        padding: 10px;

        & .ici-cell-breakline{
            margin: 0 5px;
        }

        & .ici-cell{
            width: 105px;
        }

        & .ici-cell-received,
        & .ici-cell-received-breakline,
        & .ici-cell-price,
        & .ici-cell-price-breakline,
        & .ici-cell-destination-breakline,
        & .ici-cell-trip-type-breakline{
            display: none;
        }

        & .ic-cell-see-more{
            width: 70px;
        }
    }

    .ic-empty-section{
        width: 100%;
        padding-right: 0;

        & p{
            width: 90%;
        }

        & svg{
            width: 60px;
            height: 60px;
        }
    }
}