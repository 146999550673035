.sign-in-page{
    flex-grow: 1;
    width: 100%;
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-body{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 480px;
    height: auto;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 25px 75px rgba(16,30,54,.25);
    border-radius: 6px;
    padding: 30px 60px 26px;
    transition: all ease-in-out 0.15s;
}

.sign-header{
    font-size: 25px;
    line-height: 150%;
    color: #292d34;
    text-align: center;
    margin: 20px 0 0 0;
}

.new-sign-link{
    color: rgb(63, 163, 240);
    text-align: center;
    padding: 20px;
}

.sign-in-email-container .sign-header{
    font-weight: 500;
    font-size: 20px;
}

.user-input .email-icon{
    position: absolute;
    top: 26px;
    left: 11px;
    font-size: 18px;
    font-weight: 600;
    color: #b9bec7;
}

.user-input .email-icon svg{
    position: absolute;
    top: 29px;
    left: 11px;
}

.user-input.error .helper-txt,
.sie-input-div .helper-txt{
    position: absolute;
    display: flex;
    color: #e70000;
    float: left;
    font-size: 12px;
    letter-spacing: 1px;
    z-index: 1;
}

.user-input.error .helper-txt{
    top: 60px;
}

.sie-input-div .helper-txt{
    left: 58px;
    bottom: -19px;
}

.btn-sign{
    width: 100%;
    height: 50px;
    margin-bottom: 25px;
    background: rgb(63, 163, 240);
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 0.015em;
    font-size: 17px;
    color: #fff;
    outline: none;
    border: none;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 0.8;
}

.btn-sign:hover{
    opacity: 1.0;
    cursor: pointer;
}

.btn-back-to-signin{
    width: 100%;
    height: 14px;
    font-size: 12px;
    letter-spacing: 0.015em;
    color: #2993ec;
    background-color: transparent;
    outline: none;
    border: none;
    opacity: 0.8;
    transition: all cubic-bezier(.785,.135,.15,.86) 0.15s;
}

.btn-back-to-signin:hover{
    cursor: pointer;
    opacity: 1;
}

.sign-body .names{
    display: flex;
    flex-direction: row;
}

.names .fname{
    margin-right: 5px;
}

.names .lname{
    margin-left: 5px;
}

.sign-body .error-txt{
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    color: #e70000;
    float: left;
    letter-spacing: 1px;
    z-index: 1;
}

.sign-in-error-msg-show{
    animation: sign-in-error-msg-show 0.6s ease-in-out forwards;
}


.sign-in-page{
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.sign-in-page .bg-square,
.small-screen-cover-container .bg-square{
    position: absolute;
    top: -22vw;
    right: -35vw;
    width: 70vw;
    padding-top: 100%;
    border-radius: 5%;
    background-color: rgba(63,163,240,0.1);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(35deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    z-index: 0;
}

.sign-in-page .div-logo{
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 10;

}

.sign-in-page .div-logo img{
    height: 44px;
    width: auto;
}

.div-logo a{
    position: relative;
}

.div-logo a p,
.div-logo p{
    position: absolute;
    margin: 0;
    font-size: 12px;
    color: rgb(63, 163, 240);
    font-weight: 600;
    letter-spacing: 0.04em;
    opacity: 0.8;
}

.div-logo a p{
    top: 41px;
    left: 5px;
}

.div-logo p{
    bottom: 5px;
    left: 24px;
}

.p-message{
    text-align: center;
    letter-spacing: 0.015em;
    color: #3a4554;
}

.sie-input-div{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 30px;
}

.sie-input-div input{
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    width: 35px;
    border: 1px solid #e9ebf0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 6px;
    color: #292d34;
    text-align: center;
    transition: all ease-in-out 0.15s;
}

.si-code-input-1,
.si-code-input-2,
.si-code-input-3,
.si-code-input-4,
.si-code-input-5{
    margin-right: 8px;
}

.sie-input-div.error input{
    border: 1px solid #e70000;
}

@media only screen and (max-device-width: 600px){
    .sign-body{
        width: 90vw;
        padding: 20px;
    }

    .sign-in-page .bg-square{
        top: inherit;
        bottom: 0vw;
        right: -115vw;
        width: 150vw;
        height: 150vw;
        padding-top: 100%;
        background-color: rgba(63,163,240,0.1);
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(25deg) skew(0deg, 0deg);
    }

    .sign-in-page .div-logo{
        padding: 10px;
    }

    .div-logo a p{
        top: inherit;
        bottom: -15px;
    }

    .sign-in-page{
        height: 85vh;
        min-height: 70%;
    }
}

@keyframes sign-in-error-msg-show{
    0% {
      bottom: -10px;
      opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
      bottom: 15px;
      opacity: 1;
    }
}