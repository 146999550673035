.ac-cards-container{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 30px;
}

.ac-cards-container .w-full{
    width: inherit;
}

.ac-cards-container .mx-auto{
    margin: 0;
}

.ac-charts-header-container{
    width: 160px;
}

.ach-desc-header{
    font-weight: 600;
    margin: 15px 0 2px 0;
}

@media only screen and (max-device-width: 600px){
    .ac-charts-container{
        display: none;
    }
}