/* Sidebar */
.sidebar i{
    font-size: 26px;
}

.sidebar .MuiButtonBase-root{
    margin: 10px 0;
}

.MuiListItem-root.Mui-selected,
.Mui-selected .lni{
    color: #000;
}

.MuiListItem-root .MuiListItemText-root .MuiListItemText-primary{
    color: rgba(0,0,0,0.7);
}

.MuiListItem-root.Mui-selected .MuiListItemText-root .MuiListItemText-primary{
    font-weight: 600;
    color: #000;
}

.sidebar .MuiTypography-body1{
    font-size: 18px;
    letter-spacing: 0.015em;
}

.sidebar .MuiListItem-root.Mui-selected, 
.sidebar .MuiListItem-root.Mui-selected:hover{
    background-color: rgba(63, 163, 240, 0.08);
}

.sidebar .MuiDivider-root{
    background-color: rgb(229, 231,235);
}

.sidebar .div-logo{
    position: relative;
    padding: 20px;
}

.sidebar .div-logo img{
    height: 44px;
    width: auto;
}

.sidebar .notif-count{
    position: absolute;
    top: -11px;
    left: 15px;
    color: rgb(253, 253, 253);
    background-color: #F02849;
    font-size: 14px;
    padding: 3px 5px;
    border-radius: 50%;
}

@media (min-width: 1100px){
    .header-container,
    .navigation-tab-container{
        display: none;
    }
}

@media (max-width: 1099px){
    .header-container{
        position: fixed;
        top: 0;
        left: 0;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0 4%;
        border-bottom: 1px solid #ebebeb;
        background-color: #fff;
        z-index: 100;

        & .hdr-logo{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            
            & img{
                height: 44px;
                width: auto;
            }
            & p{
                color: #3fa3f0;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: .04em;
                margin: 0 0 0 3px;
                line-height: 12px;
                opacity: .8;
            }
        }

        & .hdr-profile-btn{
            height: 100%;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .navigation-tab-container{
        position: fixed;
        bottom: 0;
        left: 0;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0 4%;
        border-top: 1px solid #ebebeb;
        background-color: #fff;
        z-index: 100;

        & .nt-btn{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & p{
                color: rgba(0,0,0,.7);
                font-size: 11px;
                letter-spacing: .04em;
                margin: 5px 0 0 0;
                line-height: 12px;
                opacity: .8;
            }
        }
    }

    .profile-container,
    .analytics-container,
    .itineraries-container,
    .places-container,
    .add-place-container,
    .finance-container{
        padding-top: 90px;
        padding-bottom: 90px;
    }
}